<template>
  <Carousel :pictures="testPictures()" class="rounded"></Carousel>
</template>
<script setup>
import { Carousel } from "flowbite-vue";

const props = defineProps({
  idCidade: Number
});

let pictures = [
  {
    src: "./assets/img/carrossel/01.jpg",
    alt: "A Nova se transformou!",
  },
    {
      src: "./assets/img/carrossel/novo_app.jpg",
      alt: "Uma experiência TOTALMENTE NOVA para você! Conheça o nosso novo app Minha Nova Telecom.",
    },
  // {
  //   src: "./assets/img/carrossel/sva_01.jpg",
  //   alt: "Vamos deixar você ligado nos maiores sucessos do cinema com a HBO Max!",
  // },
  {
    src: "./assets/img/carrossel/sva_02.jpg",
    alt: "Vá além com a nossa conexão! Ouça suas músicas favoritas com Deezer.",
  },
  {
    src: "./assets/img/carrossel/03.jpg",
    alt: "Indique um amigo e ganhe 50% de desconto em uma mensalidade!",
  },
  {
    src: "./assets/img/carrossel/banner_5.jpg",
    alt: "A família Nova cresceu! Conheça a Ivy.",
  },
  {
    src: "./assets/img/carrossel/06.jpg",
    alt: "Modo ON DIVERSÃO ativado! Dê um play na diversão com a ultravelocidade da Nova.",
  },

];

const getIdCidade = props.idCidade;

function testPictures(){
  let addPictures = new Object();
  switch(getIdCidade) {
    case "6":
      let vespazianoPictures = [
        {
          src: "./assets/img/carrossel/vespasiano_1.jfif",
          alt: "A Nova chegou em Vespasiano no MODO EXAGERAAAAAADO!",
        },
        {
          src: "./assets/img/carrossel/vespasiano_2.jfif",
          alt: "A Nova chegou em Vespasiano com oferta EXAGERADA e diversão ILIMITADA!",
        },
      ];

      addPictures = Object.assign(pictures,vespazianoPictures);
    break;

    case "7":
      let justinopolisPictures = [
        {
          src: "./assets/img/carrossel/justinopolis_1.jfif",
          alt: "A Nova chegou em Justinópolis no MODO EXAGERAAAAAADO!",
        },
        {
          src: "./assets/img/carrossel/justinopolis_2.jfif",
          alt: "A Nova chegou em Justinópolis com oferta EXAGERADA e diversão ILIMITADA!",
        },
        {
          src: "./assets/img/carrossel/03_justinopolis.jpg",
          alt: "A ultravelocidade chegou em Justinópolis com oferta MAX OFERTA Exagerada!",
        },
      ];

      addPictures = Object.assign(pictures,justinopolisPictures);
    break;

    default:
    let cidadePictures = [
    {
          src: "./assets/img/carrossel/novo_app.jpg",
          alt: "Uma experiência TOTALMENTE NOVA para você! Conheça o nosso novo app Minha Nova Telecom.",
        },
        {
          src: "./assets/img/carrossel/01.jpg",
          alt: "Agradecemos por votar na MELHOR! A sua confiança mostra que nossa conexão é com você. Seu voto já é nosso maior prêmio!",
        },
      ];

      addPictures = Object.assign(pictures,cidadePictures);
    break;
  }

  return addPictures;
}

</script>
