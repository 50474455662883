<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            FAQ
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Encontre soluções rápidas e práticas para as dúvidas mais comuns.
          </h3>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto">
            <!-- Vertical Timeline #1 -->
            <div class="-my-6">
              <faq-accordion
                faq-question="Como faço para acessar o app da Nova?"
              >
                Primeiramente é necessário realizar o download do APP. Segue o
                link abaixo conforme o sistema operacional do seu dispositivo:
                <br />
                <strong>Android: </strong>
                <a
                  class="underline"
                  href="https://play.google.com/store/apps/details?id=com.hubsoft_client_app.novatelecom"
                  target="_blank"
                  >https://play.google.com/store/apps/details?id=com.hubsoft_client_app.novatelecom</a
                >
                <br />
                <strong>IOS:</strong>
                <a
                  class="underline"
                  href="https://apps.apple.com/br/app/minha-nova-telecom/id6738741855"
                  target="_blank"
                  >https://apps.apple.com/br/app/minha-nova-telecom/id6738741855</a
                >
                <br />
                O primeiro acesso está configurado com as seguintes
                credenciais:<br />
                <strong>Usuário:</strong> “CPF do titular”<br />
                <strong>Senha:</strong> “CPF do titular”<br />
                Usuário e senha contêm somente números, sem espaço.<br />
                <strong>Exemplo:</strong> 12345678999 A senha deve ser alterada
                após o primeiro acesso.<br />
                Caso ainda assim não consiga acesso, acione nosso suporte por
                telefone ou WhatsApp no número <strong>(31)3614-9300</strong>.
              </faq-accordion>
              <faq-accordion
                faq-question="Como faço para emitir segunda via de boleto?"
              >
                O boleto pode ser retirado em nosso APP "Nova Telecom", através
                do SAC em nosso site ou por WhatsApp no número (31)3614-9300.<br />
              </faq-accordion>
              <faq-accordion
                faq-question="Qual a finalidade do botão reset em meu roteador e quando devo acioná-lo?"
              >
                O botão reset restaura as configurações de fábrica do
                equipamento.<br />
                Não é recomendável acioná-lo sem auxílio do nosso suporte
                técnico pois irá causar interrupção imediata do serviço.
              </faq-accordion>
              <faq-accordion faq-question="Como realizo o auto desbloqueio?">
                O auto desbloqueio pode ser realizado dentro do APP "Nova
                Telecom" uma única vez no mês.<br />
                Caso já tenha realizado o procedimento é necessário aguardar o
                tempo de compensação do pagamento ou entrar em contato com nosso
                suporte via WhatsApp e encaminhar uma foto do comprovante de
                pagamento.
              </faq-accordion>
              <faq-accordion
                faq-question="Como realizar a troca do nome de rede ou senha do meu Wi-Fi?"
              >
                É necessário entrar em contato com nosso suporte que irá
                orientá-lo de acordo com o aparelho que você possui.
              </faq-accordion>
              <faq-accordion
                faq-question="Por que desligar o roteador por alguns minutos é importante?"
              >
                Ao reiniciar o roteador se encerra processos que estão
                apresentando erros, atualiza-se a tabela de rotas do aparelho e
                soluciona alguns outros problemas como travamento devido a pico
                de energia dentre outros.<br />
                Sigam mais exemplos em:<br />
                <a
                  class="underline"
                  href="https://www.tecmundo.com.br/internet/9264-mito-ou-verdade-reiniciar-o-modem-faz-ainternet-voltar-.htm"
                  target="_blank"
                  >https://www.tecmundo.com.br/internet/9264-mito-ou-verdade-reiniciar-o-modem-faz-ainternet-voltar-.htm</a
                >
                <br />
              </faq-accordion>
              <faq-accordion
                faq-question="Consigo efetuar o pagamento do boleto via PIX ou cartão de crédito ou débito?"
              >
                Não é possível realizar o pagamento via PIX ou débido.
                Permitimos o pagamento via cartão de crédito através do nosso
                APP ou SAC disponível em nosso site. Abaixo o link do passo a
                passo para cadastrar seu cartão de crédito e efetuar o
                pagamento. <br />
                <a
                  class="underline"
                  href="https://rnova.com.br/Pagamento-com-cartao"
                  target="_blank"
                  >Passo a Passo Pagamento com cartão</a
                >
                <br />
              </faq-accordion>
              <faq-accordion
                faq-question="Minha velocidade não está chegando conforme o contratado, como resolvo?"
              >
                Em muitas situações os problemas de velocidade na conexão estão
                associados a capacidade de transmissão de dados e qualidade dos
                dispositivos que temos.<br />
                Para planos a partir de 100MB é necessário dispositivos que
                possuam a tecnologia de Wi-Fi 5GHz para atingir maiores
                velocidades na rede sem fio.<br />
                Em redes cabeadas é necessário dispositivos com interfaces de
                rede Gigabit Ethernet e cabo de rede apropriado.<br />
                É recomendável verificar as especificações técnicas de cada
                dispositivo, e a qualidade do aparelho pode variar muito de
                acordo com o fabricante.<br />
                Para mais informações sobre o assunto, siga:<br />
                <a
                  class="underline"
                  href="https://www.tecmundo.com.br/tecmundo-explica/60428-tecmundo-explica-wifi-diferenca-entre-2-4-ghz-5-ghz-video.htm"
                  target="_blank"
                  >https://www.tecmundo.com.br/tecmundo-explica/60428-tecmundo-explica-wifi-diferenca-entre-2-4-ghz-5-ghz-video.htm</a
                >
                <br />
                <a
                  class="underline"
                  href="https://www.techtudo.com.br/noticias/2018/04/fast-ethernet-e-gigabit-ethernet-entenda-as-diferencas-entre-padroes.ghtml"
                  target="_blank"
                  >https://www.techtudo.com.br/noticias/2018/04/fast-ethernet-e-gigabit-ethernet-entenda-as-diferencas-entre-padroes.ghtml</a
                >
                <br />
                Caso ainda assim não consiga acesso, acione nosso suporte por
                telefone ou WhatsApp no número (31)3614-9300.
              </faq-accordion>
              <faq-accordion
                faq-question="Após a instalação o equipamento é meu?"
              >
                Não. Os equipamentos ficam em comodato, ou seja, por empréstimo.
                Nesse período a responsabilidade sobre o equipamento é do
                cliente, porém a Nova Telecom fornece todo suporte e garantia,
                exceto em casos de mal uso que pode acarretar custos aos
                clientes que danificarem os aparelhos.
              </faq-accordion>
              <faq-accordion faq-question="Os planos possuem franquia?">
                Não. Você utilizará o consumo de dados ilimitados dentro da
                velocidade contratada.
              </faq-accordion>
              <faq-accordion faq-question="Minha internet tem 5G?">
                O termo 5G se refere a tecnologia de dados móveis, nós
                entregamos uma conexão banda larga através de fibra óptica.<br />
                Nossos roteadores transmitem sinal wi-fi na frequência 2.4GHz e
                5GHz disponibilizando o melhor sinal para comunicação de acordo
                com a tecnologia em que o dispositivo do cliente trabalhe.
              </faq-accordion>
              <faq-accordion
                faq-question="Meu IPTV ou TV Box está travando. Qual o problema?"
              >
                Atualmente há vários serviços disponíveis no mercado, mas o que
                muitos não sabem é a forma de funcionamento e procedência dos
                mesmos.<br />
                Confira o link abaixo e se liga na dica.<br />
              </faq-accordion>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import FaqAccordion from "@/components/layout/goods/FaqAccordion.vue";
export default {
  name: "App",
  components: {
    FaqAccordion,
  },
};
</script>

FaqAccordion
